import React, { useState } from 'react';
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import rqTeamData from '../data/rq-team-data';
import testimonialEntries from '../data/testimonial-data';
import Calendar from '../svg/Calendar'
import RefQuiz from '../svg/RefQuiz'
import RefClip from '../svg/RefClip'
import Payments from '../svg/Payments'
import Organization from '../svg/Organization'


export default () => {

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')


  function FunctionalityCard(data) {

    return (
      <div key={data.title} className="mt-4 px-3 w-full md:w-1/3 lg:w-1/4">
        <Card className="md:min-h-full">
          <div className="w-1/2 mx-auto md:w-3/4">
            {data.svg}
          </div>
          <p className="font-semibold text-xl pt-4">{data.title}</p>
          <p className="mt-4">
            {data.text}
          </p>
        </Card>
      </div>
    )
  }

  function Testimonials(name, nameSide, title, testimonial, image) {

    let left = '1'
    let right = '2'
    if (nameSide === 'right') {
      left = '2'
      right = '1'
    }

    return (
      <div className="flex flex-wrap p-4 md:p-4 lg:p-4">
        <div className={`w-full text-center md:w-1/2 md:px-4 md:order-${left} ${left === '1' ? 'md:text-left' : 'md:text-right'} md:self-center `}>

          <img
            className="w-32 h-32 m-auto rounded-full"
            src={image}
            alt={name}
          />
          <h3 className="text-center text-3xl font-semibold leading-tight">{name}</h3>

          <p className="text-center mt-8 text-xl font-light leading-relaxed">
            {title}
          </p>
        </div>
        <div className={`w-full md:w-1/2 md:px-4 md:order-${right}`}>
          <p className="mt-8 text-xl font-light leading-relaxed text-justify">
            {testimonial}
          </p>
        </div>
      </div>
    )
  }

  // 

  const functionalityCards = [
    {
      svg: <Calendar />,
      title: 'Scheduling',
      text: 'Our simple to use scheduling system includes email and text notifications, side-by-side instructor and plane availability views. Schedule with confidence!'
    },
    {
      svg: <RefQuiz />,
      title: 'Event Management',
      text: 'Keep track of the hours flown for each flight, enter lesson notes, and generate reports for flights taken. Filter by date, user, instructor or plane to see what trends your FBO is having.',
    },
    {
      svg: <Payments />,
      title: 'Payments',
      text: 'Utilize the online payment tool to lessen your processing costs. Users ALWAYS know their account balance and can keep track of their payment history.'
    },
    {
      svg: <Organization />,
      title: 'FBO Management',
      text: 'Add users, employees and planes with ease. Customize roles for employees, give users access to particular planes.'
    },
  ]

  const mainBackground = require("../media/stockbg.jpg")

  function SubmitContact() {

    console.log('sending')

    import('firebase').then(firebase => {
      firebase.initializeApp({
        apiKey: process.env.GATSBY_APP_FIREBASE_APIKEY,
        authDomain: process.env.GATSBY_APP_FIREBASE_AUTHDOMAIN,
        databaseURL: process.env.GATSBY_APP_FIREBASE_DATABASEURL,
        projectId: process.env.GATSBY_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.GATSBY_APP_FIREBASE_STORAGEBUCKET
      });
      const contactSubmit = firebase.functions().httpsCallable('contactUsSubmission')

      contactSubmit({
        name: 'Anonymous',
        email: email,
        subject: 'Landing Page Form',
        message: message
      })
    });


  }

  return (

    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`PilotFBO - Free. Simple. Fast.`}</title>
        <meta name='description' content={`PilotFBO.com is the best solution for FBO flight management.`} />
        <link rel="canonical" href="https://pilotfbo.com" />
        <link rel="icon" type="image/png" href={require('../media/favicon.png')} sizes="16x16" />
      </Helmet>
      <section id="home" className="flex flex-col overflow-auto align-center justify-around min-h-screen p-10 bg-fixed bg-top bg-cover" style={{ backgroundImage: "url(" + mainBackground + ")" }}>
        <div className="w-full pt-20 pb-20">
          <img
            className="md:w-1/2 ml-auto mr-auto rounded-lg"
            src={require('../media/PilotFBO_whitebackground.png')}
          />
        </div>

        <div className='justify-center w-full md:w-1/2 mr-auto ml-auto'>

          <div className='ml-auto mr-auto w-full'
            style={{
              overflow: 'hidden',
              paddingTop: '56.25%',
              position: 'relative',
            }}
          >
            <iframe
              src={`https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2FPFBO-DemoVideo.mp4?alt=media&token=e8cf5988-dc73-4a96-8d7e-22c1305eaaed`}
              title='Demonstration Video'
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
              }}
              height='100%'
              width='100%'
            />
          </div>
        </div>
      </section>
      <section id="features" className="py-20 lg:pt-4 landscape:mt-48">
        <div className="container mx-auto text-center">
          <h2 className="mt-16 text-3xl lg:text-5xl font-semibold">Functionality</h2>
          <div className="flex flex-wrap sm:flex-row sm:-mx-3 mt-12">

            {functionalityCards.map(cardField => {
              return FunctionalityCard(cardField)
            })}

          </div>
        </div>
      </section>
      {/* <section id="clients" className="pt-20 lg:pt-4">
        <div className="container mx-auto text-center">
          <h2 className="mt-16 text-3xl lg:text-5xl font-semibold">RefQuest Plus<br /> Clients and Sports</h2>
          <div className="flex flex-wrap sm:flex-row sm:-mx-3 mt-12">
            <div className="w-full order-1 md:w-1/2 md:pt-4 md:order-2">
              <img
                src={require('../media/RQClients.png')}
              />

            </div>
            <div className="w-1/2 order-2 md:w-1/4 md:order-1">            
              <img
                className="m-auto"
                src={require('../media/Side1.png')}
              />
            </div>
            <div className="w-1/2 order-3 md:w-1/4 md:order-3">            
              <img
                className="m-auto"
                src={require('../media/Side2.png')}
              />
            </div>
          </div>
        </div>
        <h2 className="mt-16 text-center text-3xl lg:text-5xl font-semibold">What Users Are Saying About RQ+</h2>
        <div className="">
          {testimonialEntries.map(entry => {
            return Testimonials(entry.name, entry.side, entry.title, entry.testimonial, entry.image)
          })}
        </div>
      </section> */}
      {/* <section id="stats" className="py-10 lg:pt-32">
        <div className="container mx-auto text-center">
          <h2 className="mt-16 text-center text-3xl lg:text-5xl font-semibold">The RQ+ Platform Gets Results</h2>
          <div className="flex  items-stretch sm:flex-row mt-8 lg:px-24">
            <div className="w-full m-auto sm:w-1/3">
              <StatsBox primaryText="28,000+" secondaryText="Users and growing" />
            </div>
          </div>
        </div>
      </section> */}
      <section id="about" className="py-10 lg:py-20">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-3xl text-gray-600 text-center">The Team</LabelText>
          <div className="flex flex-wrap md:-mx-3">
            {rqTeamData.map(customer => (
              <div key={customer.name} className={`mt-3 px-3 w-full md:w-1/2 ${customer.minSize ? 'lg:' + customer.minSize : 'lg:w-1/3'}`}>
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="contact" className="container mx-auto my-0 md:my-20 py-24 px-2 md:px-0 bg-black text-white lg:rounded-full text-center">
        <h3 className="text-5xl font-semibold">Are you ready to register your FBO?</h3>
        <h3 className="text-5xl font-semibold">Maybe have a few quetions?</h3>
        <p className="mt-8 text-xl font-light">
          Reach out to our sales team at <a href="mailto:sales@pilotfbo.com">sales@pilotfbo.com</a>
        </p>
      </section>
    </Layout>
  )
};
