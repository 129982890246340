import React from 'react';

export default function Organization() {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 488.146 488.146">
            <g>
                <g>
                    <path d="M270.604,120.146c13.415-8.888,21.483-23.908,21.488-40c0.008-26.51-21.476-48.006-47.986-48.014
			c-26.51-0.008-48.006,21.476-48.014,47.986c-0.005,16.102,8.065,31.134,21.488,40.028c-2.673,1.778-5.163,3.817-7.432,6.088
			c-9.024,8.974-14.085,21.185-14.056,33.912v24c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-24
			c0.036-12.735-5.026-24.956-14.056-33.936C275.766,123.947,273.276,121.916,270.604,120.146z M212.092,80.146
			c0-17.673,14.327-32,32-32c17.673,0,32,14.327,32,32s-14.327,32-32,32C226.419,112.146,212.092,97.819,212.092,80.146z
			 M276.092,176.146h-64v-16c-0.022-8.489,3.351-16.635,9.368-22.624c4.05-3.96,9.113-6.728,14.632-8v22.624c0,4.418,3.582,8,8,8
			s8-3.582,8-8v-22.608c5.519,1.272,10.582,4.04,14.632,8c6.013,5.985,9.385,14.124,9.368,22.608V176.146z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M462.604,416.146c13.415-8.888,21.483-23.908,21.488-40c-0.043-23.397-16.934-43.361-40-47.28v-48.72c0-4.418-3.582-8-8-8
			h-184v-32.408c66.127-4.418,116.151-61.606,111.733-127.733S302.219-4.147,236.092,0.272S119.941,61.878,124.359,128.005
			c4.007,59.97,51.763,107.726,111.733,111.733v32.408h-184c-4.418,0-8,3.582-8,8v48.72C17.98,333.231,0.351,357.938,4.717,384.05
			c2.195,13.132,9.753,24.759,20.863,32.096c-2.673,1.778-5.163,3.817-7.432,6.088c-9.024,8.974-14.085,21.185-14.056,33.912v24
			c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-24c0.036-12.735-5.026-24.956-14.056-33.936c-2.27-2.263-4.76-4.294-7.432-6.064
			c22.091-14.59,28.173-44.325,13.584-66.417c-7.337-11.11-18.964-18.668-32.096-20.863v-40.72h176v40.72
			c-26.112,4.366-43.741,29.073-39.375,55.184c2.195,13.132,9.753,24.759,20.863,32.096c-2.673,1.778-5.163,3.817-7.432,6.088
			c-9.024,8.974-14.085,21.185-14.056,33.912v24c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-24
			c0.036-12.735-5.026-24.956-14.056-33.936c-2.27-2.263-4.76-4.294-7.432-6.064c22.091-14.59,28.173-44.325,13.584-66.417
			c-7.337-11.11-18.964-18.668-32.096-20.863v-40.72h176v40.72c-26.112,4.366-43.741,29.073-39.375,55.184
			c2.195,13.132,9.753,24.759,20.863,32.096c-2.673,1.778-5.163,3.817-7.432,6.088c-9.024,8.974-14.085,21.185-14.056,33.912v24
			c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-24c0.036-12.735-5.026-24.956-14.056-33.936
			C467.766,419.947,465.276,417.916,462.604,416.146z M84.092,456.146v16h-64v-16c-0.022-8.489,3.351-16.635,9.368-22.624
			c12.671-12.079,32.593-12.079,45.264,0C80.741,439.511,84.114,447.656,84.092,456.146z M84.092,376.146c0,17.673-14.327,32-32,32
			s-32-14.327-32-32c0-17.673,14.327-32,32-32S84.092,358.472,84.092,376.146z M276.092,456.146v16h-64v-16
			c-0.022-8.489,3.351-16.635,9.368-22.624c12.671-12.079,32.593-12.079,45.264,0C272.741,439.511,276.114,447.656,276.092,456.146z
			 M276.092,376.146c0,17.673-14.327,32-32,32c-17.673,0-32-14.327-32-32c0-17.673,14.327-32,32-32
			C261.765,344.146,276.092,358.472,276.092,376.146z M244.092,224.146c-57.438,0-104-46.562-104-104s46.562-104,104-104
			s104,46.562,104,104C348.026,177.556,301.502,224.079,244.092,224.146z M404.092,376.146c0-17.673,14.327-32,32-32
			c17.673,0,32,14.327,32,32c0,17.673-14.327,32-32,32C418.419,408.146,404.092,393.819,404.092,376.146z M468.092,472.146h-64v-16
			c-0.022-8.489,3.351-16.635,9.368-22.624c12.671-12.079,32.593-12.079,45.264,0c6.017,5.989,9.39,14.135,9.368,22.624V472.146z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}