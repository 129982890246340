import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => (
  <header className="z-10 fixed w-full top-0 bg-white shadow ">
    <div className=" container flex flex-col sm:flex-row justify-between items-center mx-auto py-2 px-2">
      <AnchorLink className="flex items-center text-2xl font-semibold text-black" href="#home">
          PilotFBO.com
      </AnchorLink>
        
      {/* <div className='p-0'>
        <img
          className="w-1/2"
          src={require('../../media/pfbologo.png')}
        />
      </div> */}
      
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-2 text-blue-600 font-semibold" href="#features">
          Functionality
        </AnchorLink>
        <AnchorLink className="px-2 text-blue-600 font-semibold" href="#about">
          About Us
        </AnchorLink>
        <AnchorLink className="px-2 text-blue-600 font-semibold" href="#contact">
          Contact
        </AnchorLink>
        <div className="px-2 text-blue-600 font-semibold">
          <a className="text-md font-semibold text-black" target='_blank' href="https://app.pilotfbo.com">Log In</a>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
