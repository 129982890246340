export default [
    {
        name: "Sandra Walter",
        side: "left",
        title: "IHSAA Assistant Commissioner",
        testimonial:'“The IHSAA has found the user interface to be extremely easy to navigate.  The collaboration between smaller clinician teams and our officiating staff has been invaluable.  With use of the Video Library and the Training Tape features, we have held online clinics and training sessions during a time when no other contact was possible, via RefQuest Plus.  Our officials are engaged and willing to spending time on the content they receive.  Consistency throughout the entire state is our focus as we align with the NFHS rules.  RQ+ has offered a training tool that will be extended to all sports officials throughout the entire state.  RQ+ has made customer service a priority, which is greatly appreciated by our office and the officials we serve”.',
        image: require('../media/walter.jpeg')
    },{
        name: "Roger Ayers",
        side: "right",
        title: "NCAA Men’s Basketball Final Four Official",
        testimonial:'“If any official truly wants to improve, they must access RQ+.  This is easily the best online training tool I have utilized in my career.  From the flow of the site to the engagement it encourages, RQ+ is second to none.  It has undoubtedly helped me become a better official.  I am a big fan of RQ+”. ',
        image: require('../media/ayers.jpeg')
    },{
        name: "J.D. Collins",
        side: "left",
        title: "NCAA Division I Men’s Basketball National Coordinator",
        testimonial:'“RefQuest Plus is game-changing technology and the best online training tool on the market!  I have found it to be user friendly, easy to clip plays and extremely helpful to give and get feedback on key plays.  The private portal allows the coordinator to share with a few or the whole staff.  If you are in the business of teaching, training and developing officials, RefQuest Plus is the platform for you.”',
        image: require('../media/collins.jpeg')
    },{
        name: "Joan Powell",
        side: "right",
        title: "Former NCAA Women’s Volleyball National Coordinator and Current Pac-12 and MW Women’s Volleyball Coordinator",
        testimonial:'“RefQuest came our way via a referral from a colleague, just in time!  RefQuest\'s ability to prepare my official’s for our annual Summit was excellent.  The personal tutorial and follow-up with the RQ+ team were very helpful, allowing me to customize my needs.  My officials enjoyed the "Make the Call" feature and I was able to communicate with each official after they submitted their answers. The functionality is superb - best I have seen - very user-friendly.  After our Summit, we posted our new officials\' virtual welcome video and everyone was able provide comments.  RefQuest is the best online training tool for sports officials; it\'s a game changer!”',
        image: require('../media/powell.jpeg')
    },{
        name: "John Blazek",
        side: "left",
        title: "NCAA Division II Men’s Basketball National Coordinator",
        testimonial:'“I’ve never had enough time to teach and train my officials as much as I’d like. RQ+ is the best online teaching tool I’ve ever used.   It is easy to access plays, I enjoy the pie charts on the play summaries that allow officials to compare their calls to other officials. In addition, I like being able to respond to an individual or an entire group.  It’s 100% customizable based on how I’d like to communicate with my staff.  RQ+ also allows me to customize plays based on the level and conference for officials that are relevant to them.  Viewing the comments assist me in gauging each officials knowledge of the rules, how they’re applied and confidence in which they apply the ruling.   Officiating is a subjective task and I enjoy seeing my less experienced officials develop over the course of time.”  ',
        image: require('../media/blazek.jpeg')
    },{
      name: "Verne Harris",
      side: "right",
      title: "NCAA Men’s Basketball Final Four Official and Division II Men’s Basketball Conference Coordinator",
      testimonial:'"RQ+ has been a huge timesaver for me!  A big thank you to RQ+ for making my job a lot easier.  We’ve been utilizing another platform in the past and it was tedious to say the least.  With RQ+, the system is easy for all of my officials use and I no longer needed extra help from a teaching and training standpoint.  If you need an online platform for teaching, training and collaboration, then RQ+ is for you.  I highly recommend it!"',
      image: require('../media/harris.jpg')
    },{
      name: "Penny Davis",
      side: "left",
      title: "NCAA Division I Women’s Basketball National Coordinator",
      testimonial:'"RefQuest has an ease of use that makes their service ideal for any official\'s organization.  I personally see it as a game-changer in collaborating on video clips to help with the education and growth of officiating.  Their features are well thoughtout and allow for one-to-one or group sharing.  You can tell this product was designed by people who know officiating."',
      image: require('../media/davis.jpeg')
  }
]
  