import React from 'react';
import Card from './Card';

const CustomerCard = ({ customer }) => (
  <Card className="md:min-h-full">
    <div className="flex justify-between">
      <div>
        <p>{customer.name}</p>
        <p className="text-sm text-gray-600">{customer.title}</p>
      </div>
      
      <div className=''>
        <img
          className="w-12 h-12 m-1 rounded-full"
          src={customer.image}
          alt={customer.name}
        />
      </div>
      

    </div>
    <p className="mt-6 text-justify">{customer.content}</p>
  </Card>
);

export default CustomerCard;
