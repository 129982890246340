export default [
  {
    title: 'Chief Executive Officer (CEO)',
    content:
      `Clive’s primary focus at PilotFBO.com is strategic planning and is tasked with making decision regarding the infrastructure. Clive Townsend earned his Bachelor’s Degree in Physics and in Nuclear Engineering and a Masters degree in Nuclear Engineering, all at Purdue University. As he works towards his PhD in Nuclear Engineering, he serves as the Chairman of the National Organization of Test, Research and Teaching Reactors. Clive is currently holds his PPL and is based out of West Lafayette, IN.`,
    name: 'Clive Townsend',
    image: require('../media/townsend.jpg'),
    // minSize: 'w-1/2'
  },
  {
    title: 'Chief Technology Officer (CTO)',
    content:
      `Tyler's main role with PilotFBO.com is technology development. Tyler received both his Bachelor of Science (Mathematics Education, 2016) and Master of Science (Recreation and Sports Management, 2018) from Purdue Univeristy. Tyler currently officiates Men's College Basketball at the Division I, II and III levels. Joining co-owners Clive Townsend and Mike Thanos, Tyler was recently inducted into the Purdue Official's Hall of Fame.`,
    name: 'Tyler Sandmann',
    image: require('../media/sandmann.jpg')
  },
  {
    title: 'Account Executive',
    content:
      `Mike's role with PilotFBO.com is sales and is tasked with building customer relationships. Mike is currently a First Officer with Endeavor Air based out of New York City. Mike graduated from Purdue University in the Winter of 2017 in Professional Flight and his Master's in Aviation and Aerospace Management.`,
    name: 'Mike Thanos',
    image: require('../media/thanos2.jpg')
  },
];
