import React from 'react';

export default function Calendar() {
    return (
        <svg id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg">
            <path d="m456.833 32.133h-25.166v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-34.267v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-34.267v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-34.268v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-34.265v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-34.267v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-25.166c-30.419 0-55.167 24.748-55.167 55.167v369.533c0 30.419 24.748 55.167 55.167 55.167h345.433c61.426 0 111.4-49.974 111.4-111.4v-313.3c0-30.419-24.748-55.167-55.167-55.167zm-401.666 30h25.166v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h34.267v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h34.267v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h34.268v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h34.265v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h34.267v17.134c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.134h25.166c13.877 0 25.167 11.29 25.167 25.167v41.233h-452v-41.233c0-13.877 11.29-25.167 25.167-25.167zm345.433 419.867c-44.884 0-81.399-36.516-81.399-81.4s36.516-81.399 81.399-81.399c44.884 0 81.4 36.516 81.4 81.399 0 44.884-36.516 81.4-81.4 81.4zm0-192.8c-61.426 0-111.4 49.974-111.4 111.4 0 32.093 13.646 61.054 35.434 81.4h-269.467c-13.877 0-25.167-11.29-25.167-25.167v-298.3h452v166.102c-20.347-21.788-49.308-35.435-81.4-35.435z" /><path d="m159.6 208.87c-3.95 0-7.82 1.6-10.61 4.39s-4.39 6.66-4.39 10.61c0 3.939 1.6 7.81 4.39 10.6 2.8 2.79 6.66 4.39 10.61 4.39s7.81-1.6 10.61-4.39c2.79-2.79 4.39-6.66 4.39-10.6 0-3.95-1.6-7.82-4.39-10.61s-6.66-4.39-10.61-4.39z" /><path d="m223.87 208.87c-3.95 0-7.82 1.6-10.61 4.39s-4.39 6.66-4.39 10.61c0 3.939 1.6 7.81 4.39 10.6s6.66 4.39 10.61 4.39c3.941 0 7.81-1.6 10.6-4.39s4.4-6.66 4.4-10.6c0-3.95-1.61-7.82-4.4-10.61s-6.66-4.39-10.6-4.39z" />
            <path d="m288.13 208.87c-3.95 0-7.81 1.6-10.6 4.39s-4.39 6.66-4.39 10.61c0 3.949 1.6 7.81 4.39 10.6s6.66 4.39 10.6 4.39c3.95 0 7.82-1.6 10.61-4.39s4.39-6.66 4.39-10.6c0-3.95-1.6-7.82-4.39-10.61s-6.66-4.39-10.61-4.39z" />
            <path d="m352.4 208.87c-3.95 0-7.82 1.6-10.61 4.39s-4.39 6.66-4.39 10.61c0 3.939 1.6 7.81 4.39 10.6 2.8 2.79 6.66 4.39 10.61 4.39s7.82-1.6 10.61-4.39 4.39-6.66 4.39-10.6c0-3.95-1.6-7.82-4.39-10.61s-6.66-4.39-10.61-4.39z" />
            <path d="m416.67 208.87c-3.95 0-7.82 1.6-10.61 4.39s-4.39 6.66-4.39 10.61c0 3.939 1.6 7.81 4.39 10.6 2.789 2.79 6.66 4.39 10.61 4.39s7.81-1.6 10.6-4.39 4.4-6.66 4.4-10.6c0-3.95-1.61-7.82-4.4-10.61s-6.65-4.39-10.6-4.39z" />
            <path d="m95.33 273.13c-3.95 0-7.81 1.6-10.6 4.39-2.8 2.8-4.4 6.67-4.4 10.61 0 3.95 1.6 7.82 4.4 10.61 2.79 2.79 6.65 4.39 10.6 4.39s7.82-1.6 10.61-4.39 4.39-6.66 4.39-10.61c0-3.94-1.6-7.811-4.39-10.61-2.79-2.791-6.66-4.39-10.61-4.39z" />
            <path d="m159.6 273.13c-3.95 0-7.82 1.6-10.61 4.39-2.79 2.8-4.39 6.67-4.39 10.61 0 3.95 1.6 7.82 4.39 10.61 2.8 2.79 6.66 4.39 10.61 4.39s7.81-1.6 10.61-4.39c2.79-2.79 4.39-6.66 4.39-10.61s-1.6-7.811-4.39-10.61c-2.8-2.791-6.66-4.39-10.61-4.39z" />
            <path d="m223.87 273.13c-3.95 0-7.82 1.6-10.61 4.39-2.79 2.8-4.39 6.66-4.39 10.61s1.6 7.82 4.39 10.61 6.66 4.39 10.61 4.39c3.941 0 7.81-1.6 10.6-4.39s4.4-6.66 4.4-10.61c0-3.94-1.61-7.811-4.4-10.61-2.79-2.791-6.65-4.39-10.6-4.39z" />
            <path d="m288.13 273.13c-3.95 0-7.81 1.6-10.6 4.39-2.79 2.8-4.4 6.67-4.4 10.61 0 3.95 1.61 7.82 4.4 10.61s6.65 4.39 10.6 4.39 7.82-1.6 10.61-4.39 4.39-6.66 4.39-10.61c0-3.94-1.6-7.811-4.39-10.61-2.79-2.791-6.66-4.39-10.61-4.39z" />
            <path d="m95.33 337.399c-3.94 0-7.81 1.601-10.6 4.391s-4.4 6.66-4.4 10.609c0 3.95 1.61 7.811 4.4 10.61 2.79 2.79 6.66 4.39 10.6 4.39 3.95 0 7.82-1.6 10.61-4.39 2.79-2.8 4.39-6.66 4.39-10.61 0-3.949-1.6-7.81-4.39-10.609-2.79-2.79-6.66-4.391-10.61-4.391z" />
            <path d="m159.6 337.399c-3.95 0-7.82 1.601-10.61 4.391s-4.39 6.66-4.39 10.609c0 3.95 1.6 7.811 4.39 10.61 2.8 2.79 6.66 4.39 10.61 4.39s7.81-1.6 10.61-4.39c2.79-2.8 4.39-6.66 4.39-10.61 0-3.949-1.6-7.819-4.39-10.609-2.8-2.79-6.66-4.391-10.61-4.391z" />
            <path d="m223.87 337.399c-3.95 0-7.82 1.601-10.61 4.391s-4.39 6.66-4.39 10.609c0 3.95 1.6 7.811 4.39 10.61 2.79 2.79 6.66 4.39 10.61 4.39 3.941 0 7.81-1.6 10.6-4.39 2.8-2.8 4.4-6.66 4.4-10.61 0-3.949-1.6-7.819-4.4-10.609-2.79-2.79-6.65-4.391-10.6-4.391z" />
            <path d="m95.33 401.67c-3.95 0-7.81 1.6-10.6 4.39-2.8 2.79-4.4 6.66-4.4 10.601 0 3.949 1.6 7.819 4.4 10.609 2.79 2.79 6.66 4.4 10.6 4.4 3.95 0 7.81-1.61 10.61-4.4 2.79-2.79 4.39-6.66 4.39-10.609 0-3.94-1.6-7.811-4.39-10.601s-6.66-4.39-10.61-4.39z" />
            <path d="m159.6 401.67c-3.95 0-7.81 1.6-10.61 4.39-2.79 2.79-4.39 6.66-4.39 10.601 0 3.949 1.6 7.819 4.39 10.609 2.8 2.79 6.66 4.4 10.61 4.4s7.81-1.61 10.61-4.4c2.79-2.79 4.39-6.66 4.39-10.609 0-3.94-1.6-7.811-4.39-10.601-2.8-2.79-6.66-4.39-10.61-4.39z" />
            <path d="m223.87 401.67c-3.95 0-7.82 1.6-10.61 4.39s-4.39 6.66-4.39 10.601c0 3.949 1.6 7.819 4.39 10.609s6.66 4.4 10.61 4.4c3.941 0 7.81-1.61 10.6-4.4s4.4-6.66 4.4-10.609c0-3.94-1.61-7.811-4.4-10.601s-6.66-4.39-10.6-4.39z" />
            <path d="m432.733 385.6h-17.133v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v32.133c0 8.284 6.716 15 15 15h32.134c8.284 0 15-6.716 15-15-.001-8.285-6.716-15-15.001-15z" />
        </svg>
    )
}