import React from 'react';

export default function RefQuiz() {

    return (

        <svg  viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg">
            <g id="_14-test" data-name="14-test">
                <g id="glyph">
                    <path d="m184 124h144a12 12 0 0 0 12-12v-56a12 12 0 0 0 -12-12h-21.4a52.008 52.008 0 0 0 -101.2 0h-21.4a12 12 0 0 0 -12 12v56a12 12 0 0 0 12 12zm72-80a12 12 0 1 1 -12 12 12 12 0 0 1 12-12z" />
                    <path d="m168 220h48a12 12 0 0 0 12-12v-48a12 12 0 0 0 -12-12h-48a12 12 0 0 0 -12 12v48a12 12 0 0 0 12 12zm12-48h24v24h-24z" />
                    <path d="m168 324h48a12 12 0 0 0 12-12v-48a12 12 0 0 0 -12-12h-48a12 12 0 0 0 -12 12v48a12 12 0 0 0 12 12zm12-48h24v24h-24z" />
                    <path d="m264 172a12 12 0 0 0 0 24h72a12 12 0 0 0 0-24z" />
                    <path d="m336 276h-72a12 12 0 0 0 0 24h72a12 12 0 0 0 0-24z" />
                    <path d="m404 56v264a99.38 99.38 0 0 1 48 12.3v-264.3a24.027 24.027 0 0 0 -24-24h-36a12 12 0 0 1 12 12z" />
                    <path d="m120 420a12 12 0 0 1 -12-12v-352a12 12 0 0 1 12-12h-35a24.027 24.027 0 0 0 -24 24v376a24.027 24.027 0 0 0 24 24h231.3a99.38 99.38 0 0 1 -12.3-48z" />
                    <path d="m404 332a88 88 0 1 0 88 88 88.1 88.1 0 0 0 -88-88zm48.485 72.485-48 48a12 12 0 0 1 -16.97 0l-24-24a12 12 0 0 1 16.97-16.97l15.515 15.514 39.515-39.514a12 12 0 0 1 16.97 16.97z" />
                </g>
            </g>
        </svg>
    )

};


